
import {defineComponent, inject, toRefs,ref} from 'vue'
import YearPicker from '@/views/Payment/components/YearPicker.vue'
import useDealerTab from '@/hooks/payment/bu/useDealerTab'
import { elementSize } from '@/utils'
import { toThousands } from "@/utils/approve";
import { toThousandsAndTwoDecimal } from '@/utils/payment/numberFormat'
import UploadDetail from "./uploadDetail.vue";
import {PayoutBatchTableData} from "@/views/Payment/BU/types";
import {message, Modal} from "ant-design-vue";
import {ColumnProps} from "ant-design-vue/es/table/interface";
import useFetch from "@/hooks/useFetch";
import {BuItem} from "@/views/Approve/SalesRoleApprove/ProgramHome/types";
import {getSubscribedBu} from "@/API/approve";
import {useRoute,useRouter} from "vue-router";
import { useStore } from 'vuex'
import useWithdrawStatusEnable from "@/hooks/payment/useWithdrawStatusEnable";
import BuSelector from "@/views/Payment/components/BuSelector.vue";
import EntitySelector from "@/views/Payment/components/EntitySelector.vue";
import { mountedKey } from '@/views/Payment/BU/index.vue'
import { ExclamationOutlined } from '@ant-design/icons-vue';
type Key = ColumnProps['key'];
export default defineComponent({
  name: "dealerIndex",
  components: {
    YearPicker,
    UploadDetail,
    BuSelector,
    EntitySelector,
    ExclamationOutlined
  },
  props: {},
  setup() {
    const parentMounted = inject(mountedKey)
    const route = useRoute()
    const router = useRouter()
//#region [引用useDealerTab()相关]
    const {
      state,
      submitModalVisible,
      withdrawModalVisible,
      withdrawInstructions,
      deleteInstructions,
      deleteModalVisible,
      code,
      round,
      payoutId,
      statusToUpload,
      attachmentData,
      uploadHandle,
      isUploadVisible,
      rowSelectionPending,
      rowSelectionPayoutCode,
      searchParams,
      columnsPayoutBatch,
      columnsPendingCreateBatch,
      tableDataPayoutBatch,
      tableDataPendingCreateBatch,
      getSearchParamResult,
      doSearch,
      onDeleteConfirm,
      onWithdrawConfirm,
      onSubmitConfirm,
      onDownloadConfirm,
      dealerNameUpdateBtnDisabled,
      dealerNameUpdateColumns,
      dealerNameUpdateTableData,
      dealerNameUpdateModalVisible,
      onShowDealerNameUpdateModal
    } = useDealerTab()
//#endregion
    getSearchParamResult()

//#region [校验列表是否选择]
    const isSelected = (flag: string, showMessage = true) =>{
      const initFlag = ref<boolean>(true)
      switch (flag){
        case 'top':
          if ((!rowSelectionPayoutCode.selectedRowData || rowSelectionPayoutCode.selectedRowData.length === 0)) {
            showMessage && message.warning('Please select the data first');
            initFlag.value = false
          }
          break;
        case 'bottom':
          if (!rowSelectionPending.selectedRowData || rowSelectionPending.selectedRowData.length === 0) {
            showMessage && message.warning('Please select the data first');
            initFlag.value = false
          }
            break;
        case 'all':
          if ((!rowSelectionPayoutCode.selectedRowData || rowSelectionPayoutCode.selectedRowData.length === 0) &&
              (!rowSelectionPending.selectedRowData || rowSelectionPending.selectedRowData.length === 0)) {
            showMessage && message.warning('Please select the data first');
            initFlag.value = false
          }
          break;
        default:
      }
      return initFlag.value
      /*
      if ((!rowSelectionPayoutCode.selectedRowData || rowSelectionPayoutCode.selectedRowData.length === 0) &&
          (!rowSelectionPending.selectedRowData || rowSelectionPending.selectedRowData.length === 0)) {
        message.warning('Please select the data first');
        return false
      }
      else {
        return true
      }*/
    }
//#endregion
//#region [Download按钮功能]
    const onDownload = () => {
      if (!isSelected('top')) {
        return;
      }
      if (isSelected('bottom', false)){
        message.warning('pending programs can not download!');
        return;
      }
      onDownloadConfirm()
    }
//#endregion
//#region [校验是否选择同一个payoutRound进行打包]
    const isSamePayountRound = (rows: PayoutBatchTableData[]) => {
      const firstValue = rows[0].payoutRound
      const result = rows.every(function (item, index, array) {
        return item.payoutRound === firstValue
      })
      if (!result) {
        message.warning('Please select the same payoutRound to batch first');
      }
      console.log('判断是否打包同一个payoutRound=====', result)
      return result
    }
//#endregion
    //#region [Submit 按钮]
    const onSubmit = ()=>{
      // const isSelectTop = isSelected('top')
      // const isSelectBottom = isSelected('bottom')
      if (isSelected('bottom', false)){
        message.warning('pending programs can not submit!');
        return;
      }
      if (isSelected('top')) {
       {
        const flag = rowSelectionPayoutCode.selectedRowData.every((item) => {
          return item.status === 'Created'
        })
        if (flag) {
          submitModalVisible.value = true
          // Modal.warning({
          //   content: 'Please update dealer status, if need.',
          //   onOk(){
          //     submitModalVisible.value = true
          //   }
          // });
          
        } else {
          message.warning('Only the created state can be submit.');
        }
      }
    }
    }
    //#endregion
    //#region [onWithdraw 按钮]
    const onWithdraw = () => {
      if (isSelected('bottom', false)){
        message.warning('pending programs can not withdraw!');
        return;
      }
      if (isSelected('top')) {
        if (rowSelectionPayoutCode.selectedRowData.length >1){
          message.warning('Only select one item');
          return;
        }
        if (rowSelectionPayoutCode.selectedRowData && useWithdrawStatusEnable(rowSelectionPayoutCode.selectedRowData).value) {
          withdrawInstructions.value = null
          withdrawModalVisible.value = true
        } else {
          message.warning('Payout code current status can not withdraw.');
        }
      }
    }
    //#endregion
    //#region [Delete 按钮]
    const onDelete = () => {
      if (!isSelected('all')){
        return;
      }
      if (isSelected('top', false)) {
        if (rowSelectionPayoutCode.selectedRowData.length>1){
          message.warning('Only select one payoutCode to delete!');
          return;
        }
        const flag = rowSelectionPayoutCode.selectedRowData.every((item) => {
          return item.status === 'Created'
        })
        if (flag) {
          deleteModalVisible.value = true
        } else {
          message.warning('Payout code current status can not delete.');
        }
      }
      if (isSelected('bottom', false)){
        if (rowSelectionPending.selectedRowData.length>1){
          message.warning('Only select one program to delete!');
          return;
        }
        console.log('预删除的行数据=======',rowSelectionPending.selectedRowData)
        const flag = rowSelectionPending.selectedRowData.every((item) => {
          return item.status === 'Ready for Payment'
        })
        if (flag) {
          deleteModalVisible.value = true
        } else {
          message.warning('program current status can not delete.');
        }
      }
    }
    //#endregion
    const Reset = () => {
      rowSelectionPayoutCode.selectedRowKeys = []
      rowSelectionPayoutCode.selectedRowData = []
      rowSelectionPending.selectedRowKeys = []
      rowSelectionPending.selectedRowData = []
      getSearchParamResult()
      state.programCode = ""
      state.BU = []
      state.payoutStatus = []
      state.entity = ''    }
    //region [CreatePayoutBatch按钮]
    const { commit } = useStore()
    const onCreatePayoutBatch = ()=>{
      if (isSelected('top', false)){
        message.warning('Only select pending programs to create payoutCode!');
        return;
      }
      const isSelectBottom = isSelected('bottom')
      if (isSelectBottom){
        if (isSamePayountRound(rowSelectionPending.selectedRowData)){
          commit('payment/updateSelectProgramRows',rowSelectionPending.selectedRowData)
          router.push({name:'Payment Create Payout Batch',query:{type:'add', entity: state.entity}})
        }
      }
    }
    //#endregion
    //#region [关闭上传附件弹框]
    const closeUpload = (param: { isUploadVisible: boolean }) => {
      isUploadVisible.value = param.isUploadVisible
    }
    //#endregion
    const tableSize = elementSize('.ant-table-wrapper')

    // dealer setting
    const onDealerSetting = () => {
      router.push({ path: '/paymentBuManagement/dealerSetting'})
    }

    doSearch()

    return {
      parentMounted,
      ...toRefs(state),
      searchParams,
      rowSelectionPayoutCode,
      rowSelectionPending,
      tableDataPayoutBatch,
      tableDataPendingCreateBatch,
      columnsPayoutBatch,
      columnsPendingCreateBatch,
      getSearchParamResult,
      Reset,
      isSelected,
      isSamePayountRound,
      doSearch,
      submitModalVisible,
      deleteInstructions,
      deleteModalVisible,
      withdrawModalVisible,
      withdrawInstructions,
      onDownload,
      onDownloadConfirm,
      onSubmit,
      onWithdraw,
      onDelete,
      onDeleteConfirm,
      onWithdrawConfirm,
      onSubmitConfirm,
      onCreatePayoutBatch,
      isUploadVisible,
      code,
      round,
      payoutId,
      statusToUpload,
      attachmentData,
      uploadHandle,
      closeUpload,
      tableSize,
      toThousands,
      toThousandsAndTwoDecimal,
      onDealerSetting,
      state,
      dealerNameUpdateBtnDisabled,
      dealerNameUpdateColumns,
      dealerNameUpdateTableData,
      dealerNameUpdateModalVisible,
      onShowDealerNameUpdateModal
    }
  }
});
